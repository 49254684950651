import { Injectable } from "@angular/core";
import { BehaviorSubject, combineLatest, Subscription } from "rxjs";

@Injectable({
	providedIn: "root",
})
export class ProgressService {
	private readonly store = new Map<string, BehaviorSubject<boolean>>();
	private readonly _anythingInProgress$ = new BehaviorSubject(false);
	public readonly anythingInProgress$ =
		this._anythingInProgress$.asObservable();

	private combineLatestSubscription?: Subscription;

	constructor() {}

	private _getProgress(progressName: string) {
		const existingProgress$ = this.store.get(progressName);
		if (existingProgress$) return existingProgress$;

		const progress$ = new BehaviorSubject<boolean>(false);
		this.store.set(progressName, progress$);
		this.refreshAnythingInProgress();
		return progress$;
	}

	getProgressOf(progressName: string) {
		return this._getProgress(progressName).asObservable();
	}

	start(progressName: string) {
		const progress$ = this._getProgress(progressName);
		console.debug(`[progress] ⏩ ${progressName}`);
		progress$.next(true);

		return () => {
			this.stop(progressName);
		};
	}

	stop(progressName: string) {
		const progress$ = this._getProgress(progressName);
		console.debug(`[progress] ⏹️ ${progressName}`);
		progress$.next(false);
		this.store.delete(progressName);
	}

	destroy(progressNames: string[]) {
		progressNames.forEach((progressName) => {
			this.store.delete(progressName);
		});
		this.refreshAnythingInProgress();
	}

	private refreshAnythingInProgress() {
		this.combineLatestSubscription?.unsubscribe();

		this.combineLatestSubscription = combineLatest(
			Array.from(this.store.values()),
		).subscribe((results) => {
			if (results.some((value) => value)) {
				setTimeout(() => this._anythingInProgress$.next(true));
				return;
			}
			setTimeout(() => this._anythingInProgress$.next(false));
		});
	}
}
